<template>
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-dark">All Order</h1>
                        <img id="logoId" src="../../../public/images/logo.png" style="width:40px; height:40px; display:none;">
                    </div><!-- /.col -->
                    <div class="col-sm-6 text-right">
                        <router-link to="/price-changed-order" class="btn btn-info float-right">Price Changed Order</router-link>
                    </div>
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->
        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-header">
                                <form v-on:keyup.enter="searchOrder">
                                    <div class="row">
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <label for="contact">Contact</label>
                                                <input type="text" id="contact" v-model="search.contact" placeholder="Name, Mobile, Address" class="form-control">
                                            </div>
                                        </div>
                                        <div class="col-md-1">
                                            <div class="form-group">                                            
                                                <label for="sku">Order ID</label>
                                                <input type="text" id="sku" v-model="search.sku" placeholder="Order ID" class="form-control">
                                            </div>
                                        </div>
                                        <div class="col-md-1">
                                            <div class="form-group">                                            
                                                <label for="ref_id">Ref. ID</label>
                                                <input type="text" id="ref_id" v-model="search.ref_id" placeholder="Reference ID" class="form-control">
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <label for="rider_id">Rider</label>
                                                <v-select name="rider_id"
                                                    v-model="search.rider_id"
                                                    label="text"
                                                    :reduce="item => item.id"
                                                    :options= riderList
                                                    :placeholder="$t('globalTrans.select')"
                                                />
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <label for="coverage_area_id">Coverage Area</label>                                                
                                                <v-select name="coverage_area_id"
                                                    multiple
                                                    v-model="search.coverage_area_id"
                                                    label="text"
                                                    :reduce="item => item.id"
                                                    :options= commonObj.coverageAreaList
                                                    :placeholder="$t('globalTrans.select')"
                                                />
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <label for="division_id">Division</label>
                                                <v-select name="division_id"
                                                    v-model="search.division_id"
                                                    label="text"
                                                    :reduce="item => item.id"
                                                    :options= commonObj.divisionList
                                                    :placeholder="$t('globalTrans.select')"
                                                />
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <label for="district_id">District</label>
                                                <v-select name="district_id"
                                                    v-model="search.district_id"
                                                    label="text"
                                                    :reduce="item => item.id"
                                                    :options= districtList
                                                    :placeholder="$t('globalTrans.select')"
                                                />
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <label for="thana_id">Thana</label>
                                                <v-select name="thana_id"
                                                    v-model="search.thana_id"
                                                    label="text"
                                                    :reduce="item => item.id"
                                                    :options= thanaList
                                                    :placeholder="$t('globalTrans.select')"
                                                />
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <label for="area_id">Area</label>
                                                <v-select name="area_id"
                                                    v-model="search.area_id"
                                                    label="text"
                                                    :reduce="item => item.id"
                                                    :options= commonObj.areaList
                                                    :placeholder="$t('globalTrans.select')"
                                                />
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <label for="merchant_id">Merchant</label>
                                                <v-select name="merchant_id"
                                                    v-model="search.merchant_id"
                                                    label="text"
                                                    :reduce="item => item.id"
                                                    :options= commonObj.merchantList
                                                    :placeholder="$t('globalTrans.select')"
                                                />
                                            </div>
                                        </div>  
                                        <slot v-if="isDateAble">                                      
                                            <div class="col-md-2">
                                                <div class="form-group">
                                                    <label for="date_parameter_id">Date Parameter</label>
                                                    <select id="date_parameter_id" v-model="search.date_parameter_id" class="form-control">                                            
                                                        <option v-for="(item, index) in customState.dateParameterList" :value="item.id" :key="index">{{ item.text }}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <div class="form-group">
                                                    <label for="date_type_id">Date</label>
                                                    <select id="date_parameter_id" v-model="search.date_type_id" class="form-control">  
                                                        <option v-for="(item, index) in customState.dateTypeList" :value="item.id" :key="index">{{ item.text }}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <div class="form-group">
                                                    <label for="start_date">Start Date</label>
                                                    <input type="date" id="start_date" v-model="search.start_date" class="form-control">                                                
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <div class="form-group">
                                                    <label for="end_date">End Date</label>
                                                    <input type="date" id="end_date" v-model="search.end_date" class="form-control">                                                
                                                </div>
                                            </div>
                                        </slot>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <label for="source_hub_id">Source Hub</label>
                                                <v-select name="source_hub_id"
                                                    v-model="search.source_hub_id"
                                                    label="text"
                                                    :reduce="item => item.id"
                                                    :options= commonObj.hubList
                                                    :placeholder="$t('globalTrans.select')"
                                                />
                                            </div>
                                        </div>
                                        <div class="col-md-2" v-if="authUser.role_id != 7">
                                            <div class="form-group">
                                                <label for="destination_hub_id">Destination Hub</label>
                                                <v-select name="destination_hub_id"
                                                    v-model="search.destination_hub_id"
                                                    label="text"
                                                    :reduce="item => item.id"
                                                    :options= commonObj.hubList
                                                    :placeholder="$t('globalTrans.select')"
                                                />
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">                                            
                                                <label for="deposit_id">Deposit ID</label>
                                                <input type="text" id="deposit_id" v-model="search.deposit_id" placeholder="Deposit ID" class="form-control" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                            </div>
                                        </div>
                                    </div>                                    
                                    <div class="row">
                                        <div class="col-md-1">
                                            <div class="form-group">
                                                <label for="payment_status">Payment</label>
                                                <select id="payment_status" v-model="search.payment_status" class="form-control">                                            
                                                    <option :value="4">Select</option>
                                                    <option :value="1">Due</option>
                                                    <option :value="0">Unpaid</option>
                                                    <option :value="3">Paid </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-1">
                                            <div class="form-group">
                                                <label for="collection">Collection</label>
                                                <select id="collection" v-model="search.collection" class="form-control">                                            
                                                    <option :value="0">Select</option>
                                                    <option :value="1">From Rider</option>
                                                    <option :value="2">From Hub</option>
                                                    <option :value="3">Final Collection</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-10">
                                            <div class="form-group">
                                                <label for="status">Status</label>
                                                <slot v-if="authUser.role_id <= 3 || authUser.role_id == 7">
                                                    <div class="icheck-success d-inline ml-2">
                                                        <input type="radio" name="head_status" v-model="search.head_status" value="0" checked="" id="radioSuccess0">
                                                        <label for="radioSuccess0">All</label>
                                                    </div>
                                                    <div class="icheck-success d-inline ml-2">
                                                        <input type="radio" name="head_status" v-model="search.head_status" value="1" checked="" id="radioSuccess1">
                                                        <label for="radioSuccess1">Completed</label>
                                                    </div>
                                                    <div class="icheck-success d-inline ml-2">
                                                        <input type="radio" name="head_status" v-model="search.head_status" value="2" id="radioSuccess2">
                                                        <label for="radioSuccess2">Incompleted</label>
                                                    </div>
                                                    <div class="icheck-success d-inline ml-2">
                                                        <input type="radio" name="head_status" v-model="search.head_status" value="3" id="radioSuccess3">
                                                        <label for="radioSuccess3">Returnable</label>
                                                    </div>
                                                </slot>
                                                <v-select name="status"
                                                    multiple
                                                    v-model="search.status"
                                                    label="text"
                                                    :reduce="item => item.id"
                                                    :options= userStatusList
                                                    :placeholder="$t('globalTrans.select')"
                                                />   
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group" style="margin-bottom:0px;">
                                            <label>
                                                <input type="checkbox" @click="selectAll" v-model="allSelected">
                                                <p style="display: inline-block; margin-left: 4px; font-size: 15px;">Select All | Selected ({{ totalSelected }} / {{ totalOrder }}) </p>
                                            </label>
                                            <button class="btn btn-info btn-sm ml-2 mr-2" type="submit" @click="showActionPanelModal"><i class="fa fa-check"></i> Take Action</button>
                                            <button class="btn btn-info btn-sm mr-2" type="submit" @click.prevent="searchOrder"><i class="fa fa-search"></i> Filter</button>                                              
                                            <button class="btn btn-warning btn-sm" @click.prevent="reload"><i class="fa fa-sync-alt"></i></button>
                                            <a style="margin-left:10px" class="btn btn-success btn-sm" target="_blank" :href="$baseUrl + 'excel-download/order?start_date='+search.start_date+'&end_date='+search.end_date+
                                                '&merchant_id='+search.merchant_id+'&head_status='+ search.head_status +'&status='+search.status+'&coverage_area_id='+search.coverage_area_id+'&rider_id='+search.rider_id+
                                                '&source_hub_id='+search.source_hub_id+'&destination_hub_id='+search.destination_hub_id+'&coverage_area_id='+search.coverage_area_id+
                                                '&area_id='+search.area_id+'&payment_status='+search.payment_status+'&date_parameter_id='+search.date_parameter_id">
                                                <i class="fa fa-download"></i> Excel
                                            </a> 
                                            <!-- <a style="margin-left:10px" class="btn btn-success btn-sm" @click="excelDownload">
                                                <i class="fa fa-download"></i> Excel
                                            </a>  -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-header">
                                <Summary :search="search"></Summary>
                            </div>
                            
                            <Sla :merchant_id="search.merchant_id" @sla="checkSla"></Sla>
                            
                            <div class="card-body" style="margin-top: -45px">                                
                                <div class="table-responsive order-table-container">
                                    <table id="orderTable" class="table table-bordered table-sm" style="width:100%">
                                        <thead>
                                            <tr class="text-center">
                                                <th style="width:8%">Order ID</th>
                                                <th style="width:10%">Merchant</th>
                                                <th style="width:8%">Customer</th>
                                                <th style="width:11%">Address</th>
                                                <th style="width:6%">Status</th>  
                                                <th style="width:6%">Source to Destination</th>
                                                <th style="width:6%">Order Date</th>
                                                <th style="width:9%">Instruction</th>
                                                <th style="width:3%">Price</th>
                                                <th style="width:3%">Collected</th>
                                                <th style="width:5%">Delivery Charge</th>    
                                                <th style="width:5%">Merchant Amount</th>
                                                <th style="width:4%">Payment</th>      
                                                <th style="width:5%">Rider</th>     
                                                <th style="width:2%">OTP</th>     
                                                <th style="width:5%">Age (days) & Attempt</th>     
                                                <th style="width:3%">Action</th>                                       
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(order,key) in orders" :key="key" :class="(order.amount >= order.collected && order.amount_receive > 0) ? 'text-red' : 'text-black'">
                                                <td style="width:8%" :class="order.urgent == 1 ? 'text-red' : 'text-black'" :title="order.urgent == 1 ? 'Urgent' : ''"  class="order-id">
                                                    <label>
                                                        <input v-if="isSelected" v-model="ordersData" @click="singleSelect(order)" :value="order" type="checkbox" class="order-checkbox" />
                                                        <p>{{ order.id }}</p>
                                                    </label>
                                                </td>
                                                <td style="width:10%" @click="editModal(key, order)">{{ order.merchant.business }}</td>
                                                <td style="width:8%">{{ order.name }}, {{ order.mobile }}{{ order.mobile_2 ? ', '+ order.mobile_2 : '' }}</td>
                                                <td style="width:11%">{{ order.address }}</td>
                                                <td style="width:6%" class="text-center">
                                                    <span :class="'status-'+order.status">{{ getStatus(order.status) }}</span> <br>
                                                    <span>{{ order.return ? order.return.operation : '' }}</span>
                                                </td>                                    
                                                <td style="width:6%" class="text-center">{{ order.source ? order.source.name : '' }} to {{ order.destination ? order.destination.name : '' }}</td>
                                                <td style="width:6%">{{ order.date }}</td>
                                                <td style="width:9%">{{ order.instruction }}</td>
                                                <td style="width:3%" class="text-center">{{ order.price }}</td>
                                                <td style="width:3%" class="text-center">{{ order.collected }}</td>                                         
                                                <td style="width:35%" class="text-center">{{ order.service_charge }}</td>  
                                                <td style="width:5%" class="text-center">{{ order.amount_receive != 0 ? order.amount : 0 }}</td>
                                                <td style="width:4%" class="text-center"><span :class="'payment-status-'+order.payment_status">{{ order.payment_status != 0 ? getPaymentStatus(order.payment_status, order.payment_id) : '' }}</span></td>         
                                                <td style="width:5%">
                                                    <span v-if="order.rider">{{ order.rider.name }} <br/>{{ order.rider.mobile}}</span>
                                                </td>
                                                <td style="width:2%">{{ order.otp }}</td>                           
                                                <td style="width:5%" class="text-center">{{ order.age }} & {{ order.attempt }}</td>     
                                                <td style="width:3%" class="text-center">
                                                    <button v-if="(authUser.role_id <= 3 && order.status < 7)" class="btn btn-danger btn-sm mr-1" @click="deleteOrder(key, order.id)" title="Delete"><i class="fas fa-trash"></i></button>
                                                </td>
                                            </tr>
                                            <infineLoading v-if="spinner" @distance="1" @infinite="loadData"></infineLoading>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!-- /.container-fluid -->
        </section>

        <div v-if="orderEditModal" class="modal fade show" style="display:block" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-info text-white">
                        <h5 class="modal-title text-center w-100">Order Details & Edit</h5>
                        <button type="button" class="close" aria-label="Close" @click="cancelModal">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div id="accordion">
                            <div class="card">
                                <div class="card-header" id="heading1" style="padding:0px">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link" data-toggle="collapse" data-target="#collapse1" aria-controls="collapseOne">
                                            Edit
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapse1" class="collapse" aria-labelledby="heading1" data-parent="#accordion">
                                    <div class="card-body">
                                        <b-overlay :show="loading">
                                            <ValidationObserver ref="editForm" v-slot="{ handleSubmit }">
                                                <form @submit.prevent="handleSubmit(updateForm)">
                                                    <div class="row">
                                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                            <ValidationProvider name="Merchant" vid="merchant_id" v-slot="{errors}" rules="required|min_value:1">
                                                                <div class="form-group">
                                                                    <label for="merchant_id">Merchant <span class="text-danger" title="Required">*</span></label>
                                                                    <v-select name="merchant_id"
                                                                        v-model="form_edit.merchant_id"
                                                                        label="text"
                                                                        :reduce="item => item.id"
                                                                        :options= commonObj.merchantList
                                                                        :placeholder="$t('globalTrans.select')"
                                                                    />
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                            <ValidationProvider name="Name" vid="name" v-slot="{errors}" rules="required">
                                                                <div class="form-group">
                                                                    <label for="name">Name <span class="text-danger" title="Required">*</span></label>
                                                                    <input type="text" id="name" v-model="form_edit.name" class="form-control" placeholder="Customer Name" required />
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                            <ValidationProvider name="Mobile" vid="mobile" v-slot="{errors}" rules="required|min:11|max:11">
                                                                <div class="form-group">
                                                                    <label for="mobile">Mobile No<span class="text-danger" title="Required">*</span></label>
                                                                    <input type="text" id="mobile" v-model="form_edit.mobile" class="form-control" placeholder="Mobile No" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" />
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                            <ValidationProvider name="Alternatvie Mobile" vid="mobile_2" v-slot="{errors}" rules="min:11|max:11">
                                                                <div class="form-group">
                                                                    <label for="mobile_2">Alternatvie Mobile</label>
                                                                    <input type="text" id="mobile_2" v-model="form_edit.mobile_2" class="form-control" placeholder="Alternatvie Mobile No" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" />
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                            <ValidationProvider name="D" vid="coverage_area_id" v-slot="{errors}" rules="required|min_value:1">
                                                                <div class="form-group">
                                                                    <label for="coverage_area_id">Coverage Area <span class="text-danger" title="Required">*</span></label>
                                                                    <v-select name="coverage_area_id"
                                                                        v-model="form_edit.coverage_area_id"
                                                                        label="text"
                                                                        :reduce="item => item.id"
                                                                        :options= commonObj.coverageAreaList
                                                                        :disabled="true"
                                                                        :placeholder="$t('globalTrans.select')"
                                                                    />
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                            <ValidationProvider name="D" vid="division_id" v-slot="{errors}" rules="required|min_value:1">
                                                                <div class="form-group">
                                                                    <label for="division_id">Division <span class="text-danger" title="Required">*</span></label>
                                                                    <v-select name="division_id"
                                                                        v-model="form_edit.division_id"
                                                                        label="text"
                                                                        :reduce="item => item.id"
                                                                        :options= commonObj.divisionList
                                                                        :placeholder="$t('globalTrans.select')"
                                                                    />
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                            <ValidationProvider name="District" vid="district_id" v-slot="{errors}" rules="required|min_value:1">
                                                                <div class="form-group">
                                                                    <label for="district_id">District <span class="text-danger" title="Required">*</span></label>
                                                                    <v-select name="district_id"
                                                                        v-model="form_edit.district_id"
                                                                        label="text"
                                                                        :reduce="item => item.id"
                                                                        :options= districtList
                                                                        :placeholder="$t('globalTrans.select')"
                                                                    />
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                            <ValidationProvider name="Thana" vid="thana_id" v-slot="{errors}" rules="required|min_value:1">
                                                                <div class="form-group">
                                                                    <label for="thana_id">Thana <span class="text-danger" title="Required">*</span></label>
                                                                    <v-select name="thana_id"
                                                                        v-model="form_edit.thana_id"
                                                                        label="text"
                                                                        :reduce="item => item.id"
                                                                        :options= thanaList
                                                                        :placeholder="$t('globalTrans.select')"
                                                                    />
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                        <!-- <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                            <ValidationProvider name="Zone" vid="zone_id" v-slot="{errors}" rules="required|min_value:1">
                                                                <div class="form-group">
                                                                    <label for="zone_id">Zone <span class="text-danger" title="Required">*</span></label>
                                                                    <v-select name="zone_id"
                                                                        v-model="form_edit.zone_id"
                                                                        label="text"
                                                                        :reduce="item => item.id"
                                                                        :options= zoneList
                                                                        :placeholder="$t('globalTrans.select')"
                                                                    />
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div> -->
                                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                            <ValidationProvider name="Area" vid="area_id" v-slot="{errors}" rules="required|min_value:1">
                                                                <div class="form-group">
                                                                    <label for="area_id">Area <span class="text-danger" title="Required">*</span></label>
                                                                    <v-select name="area_id"
                                                                        v-model="form_edit.area_id"
                                                                        label="text"
                                                                        :reduce="item => item.id"
                                                                        :options= areaList
                                                                        :placeholder="$t('globalTrans.select')"
                                                                    />
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                            <ValidationProvider name="Destination Hub" vid="destination_hub_id" v-slot="{errors}" rules="required|min_value:1">
                                                                <div class="form-group">
                                                                    <label for="destination_hub_id">Destination Hub <span class="text-danger" title="Required">*</span></label>
                                                                    <v-select name="destination_hub_id"
                                                                        v-model="form_edit.destination_hub_id"
                                                                        label="text"
                                                                        :reduce="item => item.id"
                                                                        :options= commonObj.hubList
                                                                        :placeholder="$t('globalTrans.select')"
                                                                    />
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                            <ValidationProvider name="Category" vid="category_id" v-slot="{errors}" rules="required|min_value:1">
                                                                <div class="form-group">
                                                                    <label for="category_id">Category <span class="text-danger" title="Required">*</span></label>
                                                                    <v-select name="category_id"
                                                                        v-model="form_edit.category_id"
                                                                        label="text"
                                                                        :reduce="item => item.id"
                                                                        :options= customState.categoryList
                                                                        :placeholder="$t('globalTrans.select')"
                                                                    />
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                            <ValidationProvider name="Exchange" vid="exchange" v-slot="{errors}" rules="required|min_value:1">
                                                                <div class="form-group">
                                                                    <label for="exchange">Exchange <span class="text-danger" title="Required">*</span></label>
                                                                    <v-select name="exchange"
                                                                        v-model="form_edit.exchange"
                                                                        label="text"
                                                                        :reduce="item => item.id"
                                                                        :options= exchangeList
                                                                        :placeholder="$t('globalTrans.select')"
                                                                    />
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                            <ValidationProvider name="Ref. ID" vid="ref_id" v-slot="{errors}">
                                                                <div class="form-group">
                                                                    <label for="ref_id">Ref. ID </label>
                                                                     <input type="text" id="address" v-model="form_edit.ref_id" class="form-control" placeholder="Ref. ID" />
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                            <ValidationProvider name="Address" vid="address" v-slot="{errors}" rules="required">
                                                                <div class="form-group">
                                                                    <label for="address">Address <span class="text-danger" title="Required">*</span></label>
                                                                    <input type="text" id="address" v-model="form_edit.address" class="form-control" placeholder="Address" />
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                            <ValidationProvider name="Instruction" vid="instruction" v-slot="{errors}" rules="required">
                                                                <div class="form-group">
                                                                    <label for="instruction">Instruction <span class="text-danger" title="Required">*</span></label>
                                                                    <input type="text" id="instruction" v-model="form_edit.instruction" class="form-control" placeholder="Instruction" />
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                            <ValidationProvider name="Service Charge" vid="service_charge" v-slot="{errors}">
                                                                <div class="form-group">
                                                                    <label for="service_charge">Service Charge</label>
                                                                    <input type="text" id="service_charge" v-model="form_edit.service_charge" class="form-control" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" />
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                            <ValidationProvider name="Weight Charge" vid="weight_charge" v-slot="{errors}">
                                                                <div class="form-group">
                                                                    <label for="weight_charge">Weight Charge</label>
                                                                    <input type="text" id="weight_charge" v-model="form_edit.weight_charge" class="form-control" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" />
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                            <ValidationProvider name="Return Charge" vid="return_charge" v-slot="{errors}">
                                                                <div class="form-group">
                                                                    <label for="return_charge">Return Charge</label>
                                                                    <input type="text" id="return_charge" v-model="form_edit.return_charge" class="form-control" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" />
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                            <ValidationProvider name="Deadline" vid="date" v-slot="{errors}">
                                                                <div class="form-group">
                                                                    <label for="ref_id">Deadline <span class="text-danger" title="Required">*</span></label>
                                                                    <input type="date" id="ref_id" v-model="form_edit.date" class="form-control" placeholder="Date" :readonly="isDeadlineReadonly" />
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                            <ValidationProvider name="Amount to Collected" vid="price" v-slot="{errors}" rules="required">
                                                                <div class="form-group">
                                                                    <label for="price">Amount to Collected <span class="text-danger" title="Required">*</span></label>
                                                                    <input type="text" id="price" v-model="form_edit.price" :readonly="(form_edit.payment_status && authUser.role_id > 2) > 0 ? true : false" class="form-control" placeholder="Amount to Collected" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" />
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12" v-if="(form_edit.status >= 12 && form_edit.status <= 16) || (form_edit.status == 19 || form_edit.status == 20)">
                                                            <ValidationProvider name="Collected" vid="collected" v-slot="{errors}" rules="required">
                                                                <div class="form-group">
                                                                    <label for="price">Collected <span class="text-danger" title="Required">*</span></label>
                                                                    <input type="text" id="collected" v-model="form_edit.collected" @input="checkCollectedAmount" class="form-control" placeholder="Collected" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" />
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                            <ValidationProvider name="Status" vid="status" v-slot="{errors}" rules="required|min_value:1">
                                                                <div class="form-group">
                                                                    <label for="name">Status</label>
                                                                    <select id="status" v-model="form_edit.status" @change="statusChange($event)"  class="form-control">                                            
                                                                        <option v-for="(status,index) in mappingStatusList" :key="index" :value="status.id">{{ status.text }}</option>
                                                                    </select>
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div> 
                                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12" v-if="form_edit.status == 7">
                                                            <ValidationProvider name="Rider" vid="rider_id" v-slot="{errors}">
                                                                <div class="form-group">
                                                                    <label for="rider_id">Rider <span class="text-danger" title="Required">*</span></label>
                                                                    <v-select name="rider_id"
                                                                        v-model="form_edit.rider_id"
                                                                        label="text"
                                                                        :reduce="item => item.id"
                                                                        :options= riderList
                                                                        :placeholder="$t('globalTrans.select')"
                                                                    />
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12" v-if="form_edit.status == 18">
                                                             <ValidationProvider name="Reschedule Date" vid="reschedule_date" v-slot="{errors}">
                                                                <div class="form-group">
                                                                    <label for="reschedule_date">Reschedule Date <span class="text-danger" title="Required">*</span></label>        
                                                                    <input type="date" v-model="form_edit.reschedule_date" class="form-control" />
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </div>
                                                             </ValidationProvider>
                                                        </div>
                                                        <!-- <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12" v-if="isReason">
                                                            <ValidationProvider name="Reason" vid="reason_id" v-slot="{errors}" rules="required">
                                                                <div class="form-group">
                                                                    <label for="reason">Reason <span class="text-danger" title="Required">*</span></label>    
                                                                    <v-select name="reason_id"
                                                                        v-model="form_edit.reason_id"
                                                                        label="text"
                                                                        :reduce="item => item.id"
                                                                        :options= commonObj.reasonList
                                                                        :placeholder="$t('globalTrans.select')"
                                                                    />
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div> -->
                                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12" v-if="isReasonText">
                                                            <ValidationProvider name="Reason" vid="reason" v-slot="{errors}" rules="required">
                                                                <div class="form-group">
                                                                    <label for="reason">Reason Text <span class="text-danger" title="Required">*</span></label>        
                                                                    <input type="text" v-model="form_edit.reason" class="form-control"/>
                                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                                </div>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                                            <div class="form-group">
                                                                <button type="button" v-if="form_edit.payment_status < 2 || authUser.role_id <= 2" class="btn btn-info btn-sm mr-2 mt-30" @click.prevent="updateForm">Submit</button>
                                                                <button type="reset" class="btn btn-danger btn-sm mt-30" @click="cancelModal">Cancel</button>
                                                            </div>
                                                        </div>
                                                    </div> 
                                                </form>
                                            </ValidationObserver>
                                        </b-overlay>                                     
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="heading2" style="padding:0px">
                                    <h5 class="mb-0">
                                        <button class="btn btn-link" data-toggle="collapse" data-target="#collapse2" aria-expanded="true" aria-controls="collapseOne">
                                            Others
                                        </button>
                                    </h5>
                                </div>
                                <div id="collapse2" class="collapse show" aria-labelledby="heading2" data-parent="#accordion">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="name">Order Information</label>
                                                    <table class="table table-bordered table-sm">
                                                        <tr>
                                                            <th>Order ID : {{ form_edit.tracking_id }}</th>
                                                        </tr>
                                                        <tr>
                                                            <th>Reference ID : {{ form_edit.ref_id }}</th>
                                                        </tr>
                                                        <tr>
                                                            <th>Name : {{ form_edit.name }}</th>
                                                        </tr>
                                                        <tr>
                                                            <th>Mobile : {{ form_edit.mobile }}</th>
                                                        </tr>
                                                        <tr>
                                                            <th>Coverage Area : {{ form_edit.coverage ? form_edit.coverage.name : '' }}</th>
                                                        </tr>
                                                        <tr>
                                                            <th>Division : {{ form_edit.division ? form_edit.division.name : '' }}, District : {{ form_edit.district ? form_edit.district.name : '' }}, Thana : {{ form_edit.thana ? form_edit.thana.name : '' }}</th>
                                                        </tr>
                                                        <tr>
                                                            <th>Area : {{ form_edit.area ? form_edit.area.name : '' }}, Destination : {{ form_edit.destination ? form_edit.destination.name : '' }}</th>
                                                        </tr>
                                                        <tr>
                                                            <th>Address : {{ form_edit.address }}</th>
                                                        </tr>
                                                        <tr>
                                                            <th>Instruction : {{ form_edit.instruction }}</th>
                                                        </tr>
                                                        <tr>
                                                            <th>Order Date : {{ form_edit.date }}</th>
                                                        </tr>
                                                        <tr>
                                                            <th>Price : {{ form_edit.price }}</th>
                                                        </tr>
                                                        <tr v-if="form_edit.reason">
                                                            <th>Reason : {{ form_edit.reason }}</th>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="name">Payment Details</label>
                                                    <table class="table table-bordered table-sm">
                                                        <tr>
                                                            <th>Price</th>
                                                            <td>{{ form_edit.price }}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Collected </th>
                                                            <td>{{ form_edit.collected }}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Service Charge</th>
                                                            <td>{{ form_edit.service_charge }}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Return Charge</th>
                                                            <td>{{ (form_edit.status == 15 || form_edit.status == 19 || form_edit.status == 20) ? form_edit.return_charge : 0 }}</td>
                                                        </tr>
                                                        <!-- <tr>
                                                            <th>Area Charge</th>
                                                            <td>{{ form_edit.area_charge }}</td>
                                                        </tr> -->
                                                        <tr>
                                                            <th>Weight Charge</th>
                                                            <td>{{ form_edit.weight_charge }}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>COD Charge</th>
                                                            <td>{{ form_edit.collected > 0 ? form_edit.cod : 0 }}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Merchant Amount</th>
                                                            <td>{{ form_edit.amount_receive != 0 ? form_edit.amount : 0 }}</td>
                                                        </tr>
                                                    </table>
                                                    <button class="btn btn-success btn-sm mr-2" @click="printPos">
                                                        POS <i class="fa fa-print"></i>
                                                    </button>
                                                    <button class="btn btn-warning btn-sm mr-2" @click="printLabel">
                                                        Label <i class="fa fa-print"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="name">Order Activity</label>
                                                    <table class="table table-bordered table-sm" style="width:100%">
                                                        <tr>
                                                            <th style="width:20%">Action By</th>
                                                            <th style="width:55%">Action</th>
                                                            <th style="width:25%">Date & Time</th>
                                                        </tr>
                                                        <tr v-for="(log, index) in form_edit.logs" :key="index">
                                                            <td>{{ log.operation_by }}</td>
                                                            <td>{{ log.operation }}</td>
                                                            <td>{{ log.created_at | dateformat }} at {{ log.created_at | timeformat }}</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                        
                    </div>
                </div>
            </div>
        </div>

        <div v-if="deleteModal" class="modal fade show" style="display:block" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-default" role="document">
                <b-overlay :show="loader">
                    <div class="modal-content">
                        <div class="modal-header bg-info text-white">
                            <h5 class="modal-title text-center w-100">Delete Confirmation</h5>
                        </div>
                        <div class="modal-body">
                            <input type="hidden" name="del_id" />
                            <h3 class="text-center">Are you sure to delete ?</h3>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-info" @click="destroyOrder">Delete</button>
                            <button type="button" class="btn btn-danger" @click="cancelModal">Cancel</button>
                        </div>
                    </div>
                </b-overlay>
            </div>
        </div>

        <div v-if="actionPanelModal" class="modal fade show" style="display:block" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-info text-white">
                        <h5 class="modal-title text-center w-100">Rapid Action on Order</h5>
                        <button type="button" class="close" aria-label="Close" @click="cancelModal">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">                        
                            <input type="text" class="form-control" v-model="barcode.sku" @keyup.enter="keepOrder" placeholder="Scan barcode or type order id and press enter"/>
                        </div>
                        <div class="form-group"> 
                            <span v-for="(order, index) in orderList" :key="index" class="btn btn-sm btn-warning mb-1 mr-1">
                                {{ order }}<i class="ml-1 fas fa-times" @click="removeRow(index)"></i> 
                            </span>
                        </div>
                        <div class="form-group">   
                            <h6> 
                                Total : <b>{{ orderList.length != 0 ? orderList.length : this.totalSelected }}</b>                            
                                <button class="ml-1 btn btn-default btn-sm" @click="scanDone" v-if="isScan">Done <i class="fa fa-check"></i></button>
                                <slot v-if="loading">
                                    <div class="ml-1 spinner-border spinner-border-sm text-success" role="status"></div>
                                </slot>
                                <slot v-else>
                                    <button class="ml-1 btn btn-warning btn-sm" @click="printPos">POS <i class="fa fa-print"></i></button>
                                    <button class="ml-1 btn btn-warning btn-sm" @click="printLabel">Label <i class="fa fa-print"></i></button>
                                    <button class="ml-1 btn btn-success btn-sm" @click="actionPanelOrderToPdf">PDF <i class="fa fa-download"></i></button>
                                    <button class="ml-1 btn btn-success btn-sm">
                                        <export-excel
                                            :data="dataCustomize"
                                            :fields= "json_fields"
                                            >
                                            Excel <i class="fa fa-download"></i>
                                        </export-excel>
                                    </button>
                                    <button class="ml-1 btn btn-info btn-sm" @click="bulkAction">Submit</button>
                                    <!-- <button v-if="authUser.role_id <= 2" class="ml-1 btn btn-danger btn-sm" @click="bulkDelete">Delete</button> -->
                                </slot>
                            </h6>                        
                        </div>
                        <div class="row">
                            <div class="col-md-4" v-if="takeActionStatusList.length > 0">
                                <div class="form-group">
                                    <label for="status">Status </label>   
                                    <v-select name="status"
                                        v-model="bulk.status"
                                        label="text"
                                        :reduce="item => item.id"
                                        :options= takeActionStatusList
                                        :placeholder="$t('globalTrans.select')"
                                    />
                                    <input type="hidden" v-model="status.key">
                                </div>
                            </div>
                            <div class="col-md-4" v-if="bulk.status == 7">
                                <div class="form-group">
                                    <label for="rider_id">Rider Assign</label>
                                    <v-select name="rider_id"
                                        v-model="bulk.rider_id"
                                        label="text"
                                        :reduce="item => item.id"
                                        :options= riderList
                                        :placeholder="$t('globalTrans.select')"
                                    />
                                    <span class="text-danger" v-if="errors.merchant_id">{{ errors.merchant_id[0] }}</span>
                                </div>   
                            </div>
                            <slot v-if="authUser.role_id <= 2 || authUser.role_id == 2">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="merchant_id">Merchant </label>   
                                        <v-select name="merchant_id"
                                            v-model="bulk.merchant_id"
                                            label="text"
                                            :reduce="item => item.id"
                                            :disabled="bulk.status != 33 ? true : false"
                                            :options= commonObj.merchantList
                                            :placeholder="$t('globalTrans.select')"
                                        />
                                    </div>
                                </div>
                                <!-- <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="hub_id">Hub</label>   
                                        <v-select name="hub_id"
                                            v-model="bulk.hub_id"
                                            label="text"
                                            :reduce="item => item.id"
                                            :options= commonObj.hubList
                                            :placeholder="$t('globalTrans.select')"
                                        />
                                    </div>
                                </div> -->
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="status">Payment Status </label>                        
                                        <select class="form-control" v-model="bulk.payment_status">
                                            <option :value="1">Due</option>
                                            <option :value="3">Paid</option>
                                        </select>
                                    </div>
                                </div>
                            </slot>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- /.content -->
    </div>
</template>
<script>
    import statusHelper from '@/utils/statusMapping'
    import Pos from './Pos.js'
    import Label from './Label.js'
    import ExportPdf from './Order.js'
    import infineLoading from 'vue-infinite-loading'
    import config from '@/config'  
    import moment from 'moment'
    import Summary from './Summary.vue'
    import Sla from './Sla.vue'
    const excelColumn = {
        'Order ID': 'order_id',
        'Tracking ID': 'order_tracking_id',
        'SKU': 'order_sku',
        'Merchant': 'order_merchant_name',
        'Reference ID': 'order_ref_id',
        'Name': 'order_contact_name',
        'Number': 'order_contact_number',
        'Address': 'order_address',
        'Order Date': 'order_date',
        'Assign Date': 'order_assign_date',
        'Amount': 'order_amount',
        'Collected Amount': 'order_collected',
        'Rider': 'order_rider_name',
        'Destination': 'order_destination',
        'Status': 'order_status',
        'Instruction': 'order_instruction',
        'Reason': 'order_reason'
    }
    export default {
        name:'Order',
        components: {
            infineLoading: infineLoading,
            Summary: Summary,
            Sla: Sla
        },
        data () {
            return {
                exchangeList: [
                    { id: 1, text: 'Yes' },
                    { id: 2, text: 'NO' },
                ],
                isExcelDownload: false,
                summary: null,
                spinner: true,
                loader: true, 
                loading: false,
                isScan: false,
                isSelected: true,
                selected: [],
                allSelected: false,
                ordersData: [],
                zones:[],
                orders:[],
                totalOrder: 0,
                order_id: '',
                printorders:[],
                barcode:{
                    sku: ''
                },
                orderList: [],
                page: 1,
                search: {
                    isSearch : 0,
                    contact : '',
                    sku : '',
                    ref_id: '',
                    merchant_id : '',
                    deposit_id : '',
                    rider_id    : '',
                    coverage_area_id : '',
                    division_id : '',
                    district_id : '',
                    thana_id : '',
                    area_id : '',
                    // zone_id        : 0,
                    payment_status : 4,
                    collection : 0,
                    head_status : '',
                    status : '',
                    date_parameter_id : 2,
                    date_type_id : 2,
                    source_hub_id : '',
                    destination_hub_id : '',
                    start_date : moment().subtract(1,'d').format('YYYY-MM-DD'),
                    end_date : moment().format('YYYY-MM-DD')
                },
                form_edit:'',
                errors:[],
                createModal: 0,
                issueModal: 0,
                issue: '',
                orderEditModal:false,
                deleteModal:false,
                actionPanelModal:false,
                bulk:{
                    order_ids: [],
                    status: '',
                    merchant_id: '',
                    rider_id: '',
                    hub_id: '',
                    payment_status: 1
                },
                status:{
                    key: '',
                    sku: '',
                    status: '',
                    collected: 0,
                    reason:' ',
                    reason_id: 0,
                },
                isRescheduleDate: false,
                isReason: false,
                isReasonText: false,
                areaList: [],
                districtList: [],
                thanaList: [],
                totalSelected: 0,
                takeAction: true,
                key:'',
                mappingStatusList: [],
                takeActionStatusList: [],
                isDeadlineReadonly: false,
            }
        },
        watch: {
            stateReload: function (newVal, oldVal) {
                if (newVal != oldVal) {
                    this.searchOrder()
                }
            },
            'search.date_type_id': function (newVal, oldVal) {
                if (newVal != oldVal) {
                   this.dateTypeChange(newVal)
                }
            },
            'search.head_status': function (newVal, oldVal) {
                if (newVal != oldVal) {
                    this.search.status = this.getCustomSearchStatus(newVal)
                }
            },
            'search.coverage_area_id': function (newVal, oldVal) {
                if (newVal != oldVal) {
                    this.areaList = this.getAreaByCoverageAreaList(newVal)
                }
            },
            'search.division_id': function (newVal, oldVal) {
                if (newVal != oldVal) {
                    this.districtList = this.getDistrictList(newVal)
                }
            },
            'search.district_id': function (newVal, oldVal) {
                if (newVal != oldVal) {
                    this.thanaList = this.getThanaList(newVal)
                }
            },
            'form_edit.division_id': function (newVal, oldVal) {
                if (newVal != oldVal) {
                    this.districtList = this.getDistrictList(newVal)
                }
            },
            'form_edit.district_id': function (newVal, oldVal) {
                if (newVal != oldVal) {
                    this.thanaList = this.getThanaList(newVal)
                }
            },
            'search.destination_hub_id': function (newVal, oldVal) {
                if (newVal != oldVal) {
                    this.areaList = this.getHubAreaList(newVal)
                }
            },
            'form_edit.thana_id': function (newVal, oldVal) {
                if (newVal != oldVal) {
                    // this.zoneList = this.getZoneList(newVal)
                    this.areaList = this.getAreaList(newVal)
                }
            },
            'form_edit.weight': function (newVal, oldVal) {
                if (newVal != oldVal) {
                    this.form_edit.weight_charge = this.getWeightCharge(newVal, this.form_edit.weight_charge)
                }
            },
            // 'form_edit.reason_id': function (newVal, oldVal) {
            //     if ((newVal != oldVal) && newVal == 19) {
            //         this.isReasonText = true
            //     }
            // },
            '$route.query': function (newVal, oldVal) {
                if(newVal != oldVal) {
                    Object.assign(this.search, this.$route.query)
                    // this.loadSummary()
                    this.loadData()
                }
            }
        },
        created () {
            if (this.$route.query) {
                Object.assign(this.search, this.$route.query)
            } 
            // this.loadSummary();
            this.searchOrder();
        },
        computed : {
            stateReload () {
                return this.$store.state.stateReload
            },
            customState () {
                return this.$store.state
            },
            userStatusList () {
                return this.$store.state.userStatusList
            },
            authUser () {
                return this.$store.state.authUser
            },
            commonObj () {
                return this.$store.state.commonObj
            },
            isDateAble () {
                return (this.authUser.role_id != 5 && this.authUser.role_id != 6) ? true : false 
            },
            riderList () {
                if (this.authUser.role_id == 7) {
                    return this.commonObj.riderList.filter(el => el.hub_id == this.authUser.hub_id)
                } else {
                    return this.commonObj.riderList
                }
            },
            json_fields: function () {
                return excelColumn
            },
            dataCustomize () {
                if (this.isExcelDownload) {
                    if (this.printorders.length > 0) {
                        console.log(this.printorders)
                        this.printorders.map(item => {
                            item.order_id = item.id
                            item.order_tracking_id = item.tracking_id
                            item.order_sku = item.sku,
                            item.order_merchant_name= item.merchant.business,
                            item.order_ref_id= item.ref_id,
                            item.order_contact_name= item.name,
                            item.order_contact_number= item.mobile,
                            item.order_address= item.address,
                            item.order_date= item.date,
                            item.order_assign_date= item.assign_date,
                            item.order_amount= item.price,
                            item.order_collected= item.collected,
                            item.order_rider_name= item.rider_id ? item.rider.name : '',
                            item.order_destination= item.destination ? item.destination.name : ''
                            item.order_status= this.getStatus(item.status),
                            item.order_instruction= item.instruction,
                            item.order_reason= item.reason

                            return Object.assign({}, item)
                        })
                        return this.printorders
                    } else if (this.orders.length > 0) {
                        console.log(this.orders)
                        this.orders.map(item => {
                            item.order_id = item.id
                            item.order_tracking_id = item.tracking_id
                            item.order_sku = item.sku,
                            item.order_merchant_name= item.merchant.business,
                            item.order_ref_id= item.ref_id,
                            item.order_contact_name= item.name,
                            item.order_contact_number= item.mobile,
                            item.order_address= item.address,
                            item.order_date= item.date,
                            item.order_assign_date= item.assign_date,
                            item.order_amount= item.price,
                            item.order_collected= item.collected,
                            item.order_rider_name= item.rider_id ? item.rider.name : '',
                            item.order_destination= item.destination ? item.destination.name : ''
                            item.order_status= this.getStatus(item.status),
                            item.order_instruction= item.instruction,
                            item.order_reason= item.reason

                            return Object.assign({}, item)
                        })
                        return this.orders
                    } else {
                        return []
                    }
                }
                return []
            }
        },
        methods:{
            dateTypeChange (newVal) {
                const dateTypeId = newVal
                if (dateTypeId == 1) {
                    this.search.start_date = this.search.end_date = moment().format('YYYY-MM-DD')
                } else if (dateTypeId == 2) {
                    this.search.start_date = this.search.end_date = moment().subtract(1,'d').format('YYYY-MM-DD')
                } else if (dateTypeId == 3) {
                    this.search.start_date = moment().subtract(31,'d').format('YYYY-MM-DD')
                    this.search.end_date = moment().format('YYYY-MM-DD')
                } else if (dateTypeId == 4) {
                    this.search.start_date = moment().subtract(180,'d').format('YYYY-MM-DD')
                    this.search.end_date = moment().format('YYYY-MM-DD')
                } else if (dateTypeId == 5) {
                    this.search.start_date = moment().subtract(365,'d').format('YYYY-MM-DD')
                    this.search.end_date = moment().format('YYYY-MM-DD')
                }
            },
            reload () {
                this.commonStatus = []
                this.search = Object.assign({}, {
                    isSearch    : 0,
                    contact     : '',
                    sku         : '',
                    ref_id: '',
                    merchant_id : '',
                    deposit_id : '',
                    rider_id    : '',
                    coverage_area_id : '',
                    division_id : '',
                    district_id : '',
                    thana_id : '',
                    area_id     : '',
                    payment_status : 4,
                    collection : 0,
                    head_status : '',
                    status      : '',
                    date_parameter_id  : 2,
                    date_type_id  : 2,
                    source_hub_id  : '',
                    destination_hub_id  : this.authUser.role_id == 7 ? this.authUser.hub_id : '',
                    start_date : moment().subtract(1,'d').format('YYYY-MM-DD'),
                    end_date : moment().format('YYYY-MM-DD')
                })
                this.orderList = this.ordersData = this.printorders = []
                this.totalSelected = 0
            },
            selectAll: function() {   
                this.takeAction = false                
                this.ordersData = [];
                this.orderList = [];
                if (!this.allSelected) {
                    for (var order in this.orders) {
                        this.ordersData.push(this.orders[order])
                        this.orderList.push(this.orders[order].id);
                    }
                    this.totalSelected = this.orderList.length
                } else {
                    this.totalSelected = 0
                }
            },
            singleSelect(order) {
                this.takeAction = false
                if (!this.orderList.includes(order.id)) {
                    this.orderList.push(order.id)
                } else {
                    this.orderList.splice(this.orderList.indexOf(order.id), 1)
                }     
                this.totalSelected = this.orderList.length          
                this.allSelected = false;                
            },
            checkSla (param) {
                let startDate
                let endDate
                let coverageAreaIds
                if (param.coverage_area_id == 0) {
                    startDate = param.sla_osd_date
                    endDate = param.sla_osd_date
                    coverageAreaIds = [1,2,3]
                } else if (param.coverage_area_id == 1) {
                    startDate = param.sla_isd_date
                    endDate = param.sla_isd_date
                    coverageAreaIds = [1]
                } else if (param.coverage_area_id == 2) {
                    startDate = param.sla_osd_date
                    endDate = param.sla_osd_date
                    coverageAreaIds = [2]
                } else if (param.coverage_area_id == 3) {
                    startDate = param.sla_sa_date
                    endDate = param.sla_sa_date
                    coverageAreaIds = [3]
                }
                
                Object.assign(this.search, {
                    sla_type: param.sla_type,
                    start_date: startDate,
                    end_date: endDate,
                    coverage_area_id: coverageAreaIds,
                    date_parameter_id: 4,
                    status: [1,3,4,5,6,7,8,9,10,11,12,13,17,18,30]
                })
                this.searchOrder()
            },
            actionPanelOrderToPdf() {
                let riderName = ''
                let merchantName = ''
                if (this.search.merchant_id != '') {
                    const merchant = this.commonObj.merchantList.find(merchant => merchant.id == this.search.merchant_id)
                    merchantName = merchant != 'undefined' ? 'Merchant: '+ merchant.text : ''
                } else if (this.search.rider_id != '') {
                    const rider = this.commonObj.riderList.find(rider => rider.id == this.search.rider_id)
                    riderName = rider != 'undefined' ? 'Rider: '+ rider.text : ''
                }
                const hubId = this.search.destination_hub_id != '' ? this.search.destination_hub_id : this.authUser.hub_id
                const hub   = this.commonObj.hubList.find(el => el.id == hubId)
                const hubName= hub ? hub.text : ''
                
                const base64Logo = this.getBase64Logo(document.getElementById('logoId'))
                ExportPdf.exportPdfDetails(base64Logo, this.printorders, this.search.start_date, this.search.end_date, riderName, merchantName, hubName)
            },
            getBase64Logo (logo) {
                var canvas = document.createElement('canvas')
                canvas.width = logo.width
                canvas.height = logo.height
                var ctx = canvas.getContext('2d')
                ctx.drawImage(logo, 0, 0)
                var dataURL = canvas.toDataURL('image/png')
                return dataURL.replace(/^data:image\/(png|jpg);base64,/, '')
            },
            getStatus (status) {
                const tmpStatus = this.customState.statusList.find(tmp => tmp.id == status)
                return typeof tmpStatus != 'undefined' ? tmpStatus.text : status
            },
            getPaymentStatus (paymentStatus, paymentId) {
                if (paymentStatus === 1) {
                    return 'Due'
                } else if (paymentStatus === 2) {
                    return 'Processing'
                } else if (paymentStatus === 3) {
                    return 'Paid ID : '+ paymentId 
                }
            },
            getCustomSearchStatus (headStatus) {
                let searchAbleStatusList = []
                if (headStatus == 1) {
                    searchAbleStatusList = [14,15,16,19,20]
                } else if (headStatus == 2) {
                    searchAbleStatusList = [3,4,5,6,7,10,11,12,13,17,18,30,37,38]
                } else if (headStatus == 3) {
                    searchAbleStatusList = [21,22,23,24,25,26]
                } else if (headStatus == 0) {
                    searchAbleStatusList = []
                }
                return searchAbleStatusList
            },
            searchOrder() {
                this.search.isSearch = 1
                this.totalOrder = 0
                this.orders = []
                this.page = 1
                this.search.destination_hub_id = this.authUser.role_id == 7 ? this.authUser.hub_id : this.search.destination_hub_id
                if (this.search.status.length == 1 ) {
                    this.mappingStatusList = statusHelper.getMappingStatus(this.search.status[0])
                } else {
                    this.mappingStatusList = statusHelper.getMappingStatus(0)
                }
                // this.loadSummary()
                this.loadData()
            },
            async loadSummary() {
                this.loading = true
                const params = this.authUser.role_id == 7 ? Object.assign({}, this.search, { destination_hub_id: this.authUser.hub_id }) : this.search 
                const response = await config.getData('order/summary', params)
                this.loading = false
                if (response.success) {
                    this.summary = response.summary
                } else {
                    this.summary = null
                }
            },
            loadData($state) {
                this.$store.dispatch('summarySearch', true)
                this.loader = false
                let vm = this;
                if (this.$route.query.query_status) {
                    this.search = Object.assign(this.search, { query_status: this.$route.query.query_status})
                }
                const params = Object.assign({}, this.search, { role_id: this.authUser.role_id, page: this.page })
                config.getData('order/list', params)
                .then(res => {
                    return res
                }).then(response => {
                    // this.summary = response.summary
                    this.totalOrder += response.data.data.length
                    response.data.data.map(item => {
                        const tmpItem = this.getRelationalData(item)
                        vm.orders.push(tmpItem)
                    })
                    if (this.totalOrder < response.data.total) {
                        this.spinner = true
                        $state.loaded()
                    } else {
                        this.spinner = false
                    }
                });
                
                this.page = this.page + 1;
            },
            getRelationalData (item) {
                const start = new Date(item.date);
                const end   = new Date(item.activity_date);
                const time  = end.getTime() - start.getTime();
                const newAge= Math.ceil(time / (1000 * 3600 * 24));
                return Object.assign(item, {
                    age: newAge
                })
            },
            changeWeight(weightChargeId){
                const tmpCharge = this.commonObj.weightChargeList.find(charge => charge.id == weightChargeId)
                this.form_edit.weight_charge = tmpCharge.charge
            },
            editModal (index, order){
                this.form_edit = order
                this.mappingStatusList = statusHelper.getMappingStatus(order.status)
                document.body.classList.add("modal-open");
                this.isRescheduleDate = order.reschedule_date != null ? true : false
                // this.isReason = order.reason_id ? true : false
                this.isReasonText = order.reason != null ? true : false
                this.isDeadlineReadonly = this.checkIsDeadlineReadOnly(this.form_edit)
                this.printorders.push(this.form_edit)
                this.key = index
                this.orderEditModal = true  
            },
            checkIsDeadlineReadOnly (orderDetails) {
                return (orderDetails.status != 3 && orderDetails.status != 4 && this.authUser.role_id != 1) ? true : false
            },
            async updateForm () {
                this.loading = true
                // this.$store.dispatch('stateReload', true)
                const response = await config.postData('/order/update', this.form_edit)
                this.loading = false
                this.printorders = []
                if (response.success || response.status == 201) { 
                    this.form_edit = response.data                 
                    // this.isReason = this.isRescheduleDate = this.isReasonText = false
                    this.isRescheduleDate = this.isReasonText = false
                    this.searchOrder()
                    this.$toast.success({
                        title: 'Success',
                        message: 'Data updated successfully',
                        color: '#218838'
                    }) 
                    this.orderEditModal = false
                } else {
                    this.$toast.error({
                        title: 'Error',
                        message: response.message,
                        color: '#dc3545'
                    })
                    this.$refs.editForm.setErrors(response.errors)
                }
                // this.$store.dispatch('stateReload', false)
            },
            cancelModal(){                
                this.orderEditModal = this.deleteModal = this.actionPanelModal = false
                // this.isReason = this.isReasonText = this.isRescheduleDate = this.isScan = this.isExcelDownload = false
                this.isReasonText = this.isRescheduleDate = this.isScan = this.isExcelDownload = false
                this.allSelected = false
                this.barcode.sku = ''
                this.totalSelected = 0
                this.printorders = []
                this.ordersData = []
                this.orderList = []   
                this.mappingStatusList = []   
                this.takeActionStatusList = []   
                this.bulk.rider_id = this.bulk.status = this.bulk.merchant_id = this.bulk.hub_id = ''
                this.bulk.payment_status = 1
            },
            deleteOrder(key, order_id){
                this.order_id = order_id;
                this.key = key;
                this.deleteModal = true;
            },
            async destroyOrder(){
                this.loader = true
                this.$store.dispatch('stateReload', true)
                const response = await config.deleteData(`/order/destroy/${this.order_id}/${this.authUser.role_id}`)
                this.loader = false
                if (response.status == 200) {
                    this.deleteModal = false
                    this.$toast.success('Data deleted successfully') 
                } else {
                    this.$toast.error('Data not deleted') 
                }
                this.$store.dispatch('stateReload', false)
            },
            async bulkAction(){ 
                this.loading = true 
                if (this.orderList.length > 0) {
                    const orderIdList = this.orderList.filter(val => (val !== undefined) && (val !== null));                     
                    this.bulk.order_ids = []         
                    this.bulk.order_ids = orderIdList
                }
                const skuList = this.barcode.sku.split(" ")
                if (!this.isScan && skuList.length > 0) {
                    skuList.forEach((value) => {
                        const orderId = value.split("-")[2]
                        this.bulk.order_ids.push(orderId)
                    })
                }
                const bulkParams = Object.assign(this.bulk, { search_status: this.search.status })
                const response = await config.postData('/order/bulk-action', bulkParams)
                this.loading = false 
                if (response.status == 201){   
                    this.orders = response.data
                    this.bulk.status = this.bulk.merchant_id = this.bulk.rider_id = this.bulk.hub_id = ''
                    this.bulk.payment_status = 1
                    this.$toast.success({
                        title: 'Success',
                        message: response.message,
                        color: '#218838'
                    })
                } else {
                    this.$toast.error({
                        title: 'Error',
                        message: response.message,
                        color: '#dc3545'
                    })
                }
            },
            async bulkDelete(){  
                if (this.orderList.length > 0) {
                    const orderIdList = this.orderList.filter(val => (val !== undefined) && (val !== null));                     
                    this.bulk.order_ids = []         
                    this.bulk.order_ids = orderIdList
                }
                this.loader = true
                const response = await config.postData('/order/bulk-delete', this.bulk)
                this.loader = false
                if (response.status == 200){   
                    this.orders = [] 
                    this.bulk.status = this.bulk.merchant_id = this.bulk.rider_id = this.bulk.hub_id = ''
                    this.bulk.payment_status = 1
                    this.$toast.success({
                        title: 'Success',
                        message: response.message,
                        color: '#218838'
                    })
                } else {
                    this.$toast.error({
                        title: 'Error',
                        message: response.message,
                        color: '#dc3545'
                    })
                }
            },
            showActionPanelModal () {
                this.isScan = true
                this.actionPanelModal = true
                this.bulk.merchant_id = this.search.merchant_id
                this.takeActionStatusList = this.search.status ? statusHelper.getTakeActionStatus(this.search.status) : ''
                document.body.classList.add("modal-open");
            },
            keepOrder() { 
                let skuList = this.barcode.sku.split(" ")
                if(skuList.length > 1) {
                    skuList.map(item => {
                        this.orderList.push(item);
                    })
                } else {
                    this.orderList.push(this.barcode.sku); 
                }
                this.isScan = true  
                this.barcode.sku = ''
            },
            async scanDone () {
                this.loading = true
                const response = await config.postData('/order/scaned-order', { 'status': this.search.status, 'order_ids': this.orderList })                        
                this.loading = false                                          
                this.isScan = false                                          
                if (response.status === 200) {                                           
                    this.printorders = response.data;
                    this.isExcelDownload = true
                } else {
                    this.$toast.error({
                        title: 'Error',
                        message: 'Data not Found !!',
                        color: '#dc3545'
                    })
                }
            },
            removeRow(index){
                this.isScan = true
                this.orderList.splice(index, 1)
            },
            // printPos() {
            //     this.$htmlToPaper("printSection");
            // },
            printPos() {
                const base64Logo = this.getBase64Logo(document.getElementById('logoId'))
                Pos.expostPosDetails(base64Logo, this.printorders)
            },
            printLabel() {
                const base64Logo = this.getBase64Logo(document.getElementById('logoId'))
                Label.exportLabelDetails(base64Logo, this.printorders)
            },
            statusChange (event) {
                // this.isRescheduleDate = this.isReason = this.isReasonText = false
                this.isRescheduleDate = this.isReasonText = false
                var status = event.target.value
                this.form_edit.collected = (status == 14 || status == 19) ? this.form_edit.price : ((status == 15 || status == 20) ? this.form_edit.collected : 0)
                // if (status == 18) {
                //     this.isRescheduleDate = true
                //     this.isReason = true
                // } else                 
                if ((status >= 15 && status <= 19) || (status == 2 || status == 6 || status == 30 || status == 20)) {
                    // this.isReason = true
                    this.isReasonText = true
                }
            },
            getDateParameter (dateParameterId) {
                const dateParameter = this.customState.dateParameterList.find(param => param.id == dateParameterId)
                return dateParameter.text
            },
            getRiderName (riderId) {
                const rider = this.commonObj.riderList.find(rider => rider.id == riderId)
                return rider.text
            },
            getMerchantName (merchantId) {
                const merchant = this.commonObj.merchantList.find(merchant => merchant.id == merchantId)
                return merchant.text
            },
            getWeightCharge (weight, weightCharge) {
                const tmpCharge = this.commonObj.weightChargeList.find(el => el.id == weight)
                return typeof tmpCharge != 'undefined' ? tmpCharge.charge : weightCharge
            },
            getCategoryName (categoryId) {
                const category = this.customState.categoryList.find(cat => cat.id === categoryId)
                return  category.text
            },
            getDistrictList (divisionId) {
                return this.commonObj.districtList.filter(el => el.division_id == divisionId)
            },
            getThanaList (districtId) {
                return this.commonObj.thanaList.filter(el => el.district_id == districtId)
            },
            getAreaByCoverageAreaList (coverageAreaId) {
                return this.commonObj.areaList.filter(el => el.coverage_area_id == coverageAreaId)
            },
            getZoneList (thanaId) {
                return this.commonObj.zoneList.filter(el => el.thana_id == thanaId)
            },
            getAreaList (thanaId) {
                return this.commonObj.areaList.filter(el => el.thana_id == thanaId)
            },
            getHubAreaList (hubId) {
                return this.commonObj.areaList.filter(el => el.hub_id == hubId)
            },
            checkCollectedAmount () {
                this.isReasonText = (parseFloat(this.form_edit.price) != parseFloat(this.form_edit.collected) || this.form_edit.status == 19) ? true : false                
            },
            async excelDownload () {
                try {
                    const token = 'AC24d3a54eaf96937bebd3fa49f9ec7b2a'; 
                    const url = `http://localhost:8000/api/excel-download/order`;

                    const response = await fetch(url, this.search, {
                        method: 'GET',
                        headers: {
                            'token': token
                        },
                    });

                    // Process the response
                    console.log(response);

                }  catch (error) {
                    this.$toast.error({
                        title: 'Error',
                        message: 'Sorry, something went wrong',
                        color: '#dc3545'
                    })
                }
            },
        }
    }
</script>
<style scoped>
    @media print{
        body {
            margin-left:-10px;
        }
    }
    .mt-30 {
        margin-top: 30px !important;
    }
</style>